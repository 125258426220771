
import Vue        from 'vue'
import { Viewer } from "@/logic/Viewer";

export default Vue.extend( {
    name: 'Main',

    data: function(): any {
        return {
            canvas: null,
            viewer: null
        }
    },

    computed: {
        loading: function(): boolean {
            if( !this.viewer ) {
                return true;
            }

            return this.viewer.loading;
        }
    },

    mounted: function(): void {
        this.canvas = this.$refs.renderCanvas as HTMLCanvasElement;
        this.viewer = new Viewer( this.canvas );

        setTimeout( () => {
            this.viewer.init()
        }, 100 );
    },
} )
