import {
    ArcRotateCamera,
    Camera,
    CircleEase,
    Color3,
    Color4,
    ColorCorrectionPostProcess,
    ColorCurves,
    CubeTexture, CubicEase,
    DefaultRenderingPipeline,
    EasingFunction,
    Engine,
    ExponentialEase,
    Mesh,
    Nullable,
    PBRMaterial,
    PointLight,
    Quaternion,
    Scene,
    Texture,
    TonemappingOperator,
    Tools,
    Vector3,
    VertexBuffer,
    VertexData
} from "@babylonjs/core";
import { GsMesh }             from "@/logic/GsMesh";
import { PostProcessOptions } from "@babylonjs/core/PostProcesses/postProcess";

let IS_MOBILE = false;
if( /Mobi/.test( navigator.userAgent ) ) {
    IS_MOBILE = true;
}

export class Viewer {
    
    public static readonly CLEAR_COLOR               = Color4.FromHexString( "#b09cdb00" );
    public static readonly CAMERA_3D_FOV             = Tools.ToRadians( 40.0 );
    public static readonly CAMERA_3D_NEAR            = 0.01;
    public static readonly CAMERA_3D_FAR             = 100.0;
    public static readonly CAMERA_3D_ALPHA           = Tools.ToRadians( 90.0 );
    public static readonly CAMERA_3D_BETA            = Tools.ToRadians( 90.0 );
    public static readonly CAMERA_3D_RADIUS          = 25.0;
    public static readonly FRAMES_TO_RENDER_ON_INPUT = 60;
    public static readonly ENVIRONMENT_PATH          = "./environments/contrast.env";
    
    private readonly _canvas: Nullable<HTMLCanvasElement>;
    
    private _engine: Nullable<Engine>;
    private _scene: Nullable<Scene>;
    private _camera: Nullable<ArcRotateCamera>;
    private _material: Nullable<PBRMaterial>;
    private _mesh: Nullable<Mesh>;
    private _shape: Nullable<GsMesh>;
    
    private _remainingFrameCount: number;
    private _loading: boolean;
    
    
    constructor( canvas: Nullable<HTMLCanvasElement> ) {
        this._canvas   = canvas;
        this._engine   = null;
        this._scene    = null;
        this._camera   = null;
        this._material = null;
        this._mesh     = null;
        this._shape    = null;
        
        this._remainingFrameCount = 0;
        this._loading             = true;
    }
    
    public async init(): Promise<void> {
        return new Promise<void>( (resolve, reject) => {
            if( !this.initEngine() ) {
                return reject( "Unable to init engine." );
            }
    
            if( !this.initScene() ) {
                return reject( "Unable to init scene." );
            }
    
            if( !this.initCamera() ) {
                return reject( "Unable to init camera." );
            }
    
            if( !this.initEnvironment() ) {
                return reject( "Unable to init environment." );
            }
    
            if( !this.initPipeline() ) {
                return reject( "Unable to init rendering pipeline." );
            }
    
            if( !this.initMaterial() ) {
                return reject( "Unable to init material." );
            }
    
            if( !this.initShape() ) {
                return reject( "Unable to init mesh." );
            }
    
            if( !this.initMesh() ) {
                return reject( "Unable to init mesh." );
            }
    
            if( !this.start() ) {
                return reject( "Unable to start rendering." );
            }
    
            this.show().then( () => {
               resolve();
            } );
        } );
    }
    
    public render(): void {
        this._remainingFrameCount = Viewer.FRAMES_TO_RENDER_ON_INPUT;
    }
    
    public get loading(): boolean {
        return this._loading;
    }
    
    private start(): boolean {
        if( !this._engine ) {
            return false;
        }
        
        this._engine.runRenderLoop( () => {
            this._remainingFrameCount--;
            
            this._camera?.update();
            
            if( this._remainingFrameCount > 0 ) {
                this.renderLoop();
            }
            else {
                this._remainingFrameCount = 0;
            }
        } );
        
        return true;
    }
    
    private renderLoop(): void {
        if( !this._engine || !this._scene ) {
            return;
        }
        
        this._scene.render();
    }
    
    private initEngine(): boolean {
        if( !this._canvas ) {
            return false;
        }
        
        this._engine = new Engine( this._canvas, true );
        
        if( this._engine == null ) {
            return false;
        }
        
        const hardwareScaling = 1.0 / window.devicePixelRatio;
        this._engine.setHardwareScalingLevel( hardwareScaling );
    
        this._canvas.addEventListener( "dblclick", ( e: MouseEvent ) => {
            this.updateShape();
            this.render();
        } );
    
        let tapedTwice = false;
        
        this._canvas.addEventListener( "touchstart", (e: TouchEvent) => {
            if(!tapedTwice) {
                tapedTwice = true;
                setTimeout( function() { tapedTwice = false; }, 300 );
                return false;
            }
            e.preventDefault();
            
            this.updateShape();
            this.render();
        } );
        
        window.addEventListener( "resize", () => {
            setTimeout( () => {
                if( !this._engine ) {
                    return;
                }
                
                this._engine.resize();
                this.render();
            }, 0 );
        } );
        
        window.dispatchEvent( new Event( 'resize' ) );
        return true;
    }
    
    private initScene(): boolean {
        if( !this._engine ) {
            return false;
        }
        
        this._scene = new Scene( this._engine );
        
        this._scene.ambientColor = Color3.Black();
        this._scene.clearColor   = new Color4( 0, 0, 0, 0 );
        
        this._scene.beforeRender = () => {
            if( !this._mesh ) {
                return;
            }
            
            const rotation = Quaternion.RotationAxis( Vector3.Up(), -this._engine!.getDeltaTime() * 0.002 );
            this._mesh.rotationQuaternion = rotation.multiply( this._mesh.rotationQuaternion! );
            this.render();
        }
        
        return true;
    }
    
    private initCamera(): boolean {
        if( !this._scene ) {
            return false;
        }
        
        this._camera                         = new ArcRotateCamera( "Camera", Viewer.CAMERA_3D_ALPHA, Viewer.CAMERA_3D_BETA, Viewer.CAMERA_3D_RADIUS, new Vector3(0, -0.1, 0), this._scene );
        this._camera.fov                     = Viewer.CAMERA_3D_FOV;
        this._camera.minZ                    = Viewer.CAMERA_3D_NEAR;
        this._camera.maxZ                    = Viewer.CAMERA_3D_FAR;
        this._camera.radius                  = Viewer.CAMERA_3D_RADIUS;
        
        this._scene.activeCamera                = this._camera;
        this._scene.preventDefaultOnPointerDown = false;
        this._scene.preventDefaultOnPointerUp   = false;
        
        return true;
    }
    
    private initEnvironment(): boolean {
        if( !this._scene ) {
            return false;
        }
        
        const texture                  = CubeTexture.CreateFromPrefilteredData( Viewer.ENVIRONMENT_PATH, this._scene );
        texture.rotationY              = -0.7;
        this._scene.environmentTexture = texture;
        this._scene.environmentIntensity = 2.5;
        
        return true;
    }
    
    private initPipeline(): boolean {
        if( !this._scene || !this._camera ) {
            return false;
        }
        
        this._scene.imageProcessingConfiguration.contrast                     = 2.5;
        this._scene.imageProcessingConfiguration.colorCurvesEnabled           = true;
        this._scene.imageProcessingConfiguration.colorCurves                  = new ColorCurves();
        this._scene.imageProcessingConfiguration.colorCurves.globalSaturation = -20;
        this._scene.imageProcessingConfiguration.toneMappingEnabled           = true;
        this._scene.imageProcessingConfiguration.toneMappingType              = TonemappingOperator.Reinhard;
        
        return true;
    }
    
    private initMaterial(): boolean {
        if( !this._scene ) {
            return false;
        }
        
        this._material                  = new PBRMaterial( "material", this._scene );
        this._material.albedoColor      = new Color3( Math.pow(Viewer.CLEAR_COLOR.r, 2.2), Math.pow(Viewer.CLEAR_COLOR.g, 2.2), Math.pow(Viewer.CLEAR_COLOR.b, 2.2) );
        this._material.metallic         = 0.0;
        this._material.roughness        = 0.8;
        this._material.metallicF0Factor = 0.15;
        
        return true;
    }
    
    private initShape(): boolean {
        this._shape = new GsMesh();
        return true;
    }
    
    private initMesh(): boolean {
        if( !this._scene || !this._shape ) {
            return false;
        }
        
        let rotation = Quaternion.RotationAxis(Vector3.Up(), -Math.PI).multiply( Quaternion.RotationAxis( Vector3.Right(), -Math.PI * 0.5 ) );
        
        if( this._mesh ) {
            rotation = this._mesh.rotationQuaternion!.clone();
            this._mesh.dispose();
        }
        
        this._mesh = new Mesh( "mesh", this._scene );
        
        const vertexData     = new VertexData();
        vertexData.positions = this._shape.positions.slice();
        vertexData.indices   = this._shape.indices.slice();
        vertexData.normals   = this._shape.normals.slice();
    
        vertexData.applyToMesh( this._mesh, false );
        
        this._mesh.material           = this._material;
        this._mesh.rotationQuaternion = rotation;
        this._mesh.scaling            = Vector3.Zero();
        
        return true;
    }
    
    private async updateShape(): Promise<void> {
        return new Promise<void>( (resolve, reject) => {
            if( !this._mesh || !this._shape ) {
                return reject( "Mesh or Shape is null." );
            }
    
            const easingFunction = new CubicEase();
            easingFunction.setEasingMode( EasingFunction.EASINGMODE_EASEOUT );
    
            this.hide().then( () => {
                this._shape!.recreate();
                this.initMesh();
        
                return this.show();
            } ).then( () => {
               return resolve();
            } );
        } );
    }
    
    private async hide(): Promise<void> {
        return new Promise<void>( (resolve) => {
            const easingFunction = new CubicEase();
            easingFunction.setEasingMode( EasingFunction.EASINGMODE_EASEOUT );
    
            let s = 1.0;
    
            const interval = setInterval( () => {
                s -= 0.05;
        
                let scale = easingFunction.ease( s );
                if( scale <= 0 ) {
                    scale = 0;
                }
        
                this._mesh!.scaling = new Vector3( scale, scale, scale );
                this.render();
        
                if( scale <= 0 ) {
                    clearInterval( interval );
                    
                    this._loading = true;
                    
                    setTimeout( () => {
                        return resolve();
                    }, 100 );
                }
            }, 16 );
        } );
    }
    
    private async show(): Promise<void> {
        return new Promise<void>( (resolve) => {
            this._loading = false;
            
            const easingFunction = new CubicEase();
            easingFunction.setEasingMode( EasingFunction.EASINGMODE_EASEIN );
            
            let s = 0.0;
            
            const interval = setInterval( () => {
                s += 0.05;
                
                let scale = easingFunction.ease( s );
                if( scale >= 1 ) {
                    scale = 1;
                }
                
                this._mesh!.scaling = new Vector3( scale, scale, scale );
                this.render();
                
                if( scale >= 1 ) {
                    clearInterval( interval );
                    
                    setTimeout( () => {
                        return resolve();
                    }, 100 );
                }
            }, 16 );
        } );
    }
}