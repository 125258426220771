import { Color4, CSG, Mesh, MeshBuilder, Nullable, Quaternion, Vector3, Vector4, VertexBuffer, VertexData } from "@babylonjs/core";
import { FloatArray, IndicesArray } from "@babylonjs/core/types";
import { create, RandomSeed }       from "random-seed"

export class GsMesh {
    
    public static readonly SUBDIVISIONS  = 3;
    public static readonly EXTRUSION_MIN = 0.9;
    public static readonly EXTRUSION_MAX = 1.6;
    public static readonly SCALE_MIN     = 0.7;
    public static readonly SCALE_MAX     = 1.1;
    public static readonly ROTATION_MIN  = -Math.PI * 0.25;
    public static readonly ROTATION_MAX  = 0;
    
    public static readonly HANGER_THICKNESS = 0.2;
    public static readonly HANGER_DIAMETER  = 1.4;
    public static readonly HANGER_SPACING   = 0.5;
    
    private _quads:      Array<Vector4>;
    private _positions:  Array<Vector3>;
    private _extrudable: Array<number>;
    
    private _subPositions: FloatArray;
    private _subNormals:   FloatArray;
    private _subIndices:   IndicesArray;
    
    private _randomGenerator: RandomSeed;
    
    public constructor() {
        this._quads      = new Array<Vector4>();
        this._positions  = new Array<Vector3>();
        this._extrudable = new Array<number>();
    
        this._subPositions = new Array<number>();
        this._subNormals   = new Array<number>();
        this._subIndices   = new Array<number>();
        
        this._randomGenerator = create( "" );
        
        this.recreate();
    }
    
    public recreate(): void {
        const seed            = Date.now().toString();
        this._randomGenerator = create( seed );
        
        console.log( "SEED: " + seed )
        
        this.initCube();
    
        this.randomExtrude();
        this.randomExtrude();
        this.randomExtrude();
        this.randomExtrude();
        this.randomExtrude();
        this.randomExtrude();
        
        this.subdivide();
        this.boolean();
    }
    
    public get positions(): FloatArray {
        return this._subPositions;
    }
    
    public get normals(): FloatArray {
        return this._subNormals;
    }
    
    public get indices(): IndicesArray {
        return this._subIndices;
    }
    
    private initCube(): void {
        this._quads      = new Array<Vector4>();
        this._positions  = new Array<Vector3>();
        this._extrudable = new Array<number>();
        
        this._positions.push( new Vector3(-1, -1, -1) );
        this._positions.push( new Vector3(-1, -1,  1) );
        this._positions.push( new Vector3( 1, -1,  1) );
        this._positions.push( new Vector3( 1, -1, -1) );
        this._positions.push( new Vector3(-1,  1, -1) );
        this._positions.push( new Vector3(-1,  1,  1) );
        this._positions.push( new Vector3( 1,  1,  1) );
        this._positions.push( new Vector3( 1,  1, -1) );
        
        this._quads.push( new Vector4(0, 1, 2, 3) );
        this._quads.push( new Vector4(7, 6, 5, 4) );
        this._quads.push( new Vector4(3, 7, 4, 0) );
        this._quads.push( new Vector4(1, 5, 6, 2) );
        this._quads.push( new Vector4(2, 6, 7, 3) );
        this._quads.push( new Vector4(0, 4, 5, 1) );
    
        //this._extrudable.push( 0 );
        this._extrudable.push( 1 );
        this._extrudable.push( 2 );
        //this._extrudable.push( 3 );
        this._extrudable.push( 4 );
        //this._extrudable.push( 5 );
    }
    
    private subdivide(): void {
        const cells     = new Array<Array<number>>();
        const positions = new Array<Array<number>>();

        for( let i = 0; i < this._quads.length; ++i ) {
            const quad = this._quads[i];
            cells.push( [quad.x, quad.y, quad.z, quad.w] );
        }

        for( let i = 0; i < this._positions.length; ++i ) {
            const position = this._positions[i];
            positions.push( [position.x, position.y, position.z] );
        }

        const catmullClark = require("gl-catmull-clark");
        const result = catmullClark( positions, cells, GsMesh.SUBDIVISIONS, true );

        this._subPositions = result.positions.flat();
        this._subIndices   = result.cells.flat();
        this._subNormals   = new Array<number>();
    
        VertexData.ComputeNormals( this._subPositions, this._subIndices, this._subNormals );
    }
    
    private boolean(): void {
        const shape          = new Mesh( "shape" );
        const vertexData     = new VertexData();
        vertexData.positions = this._subPositions.slice();
        vertexData.indices   = this._subIndices.slice();
        vertexData.normals   = this._subNormals.slice();
        vertexData.applyToMesh( shape, false );
        
        shape.position.y = 0.5;
    
        const box1 = MeshBuilder.CreateBox("box1", {size: 10} );
        box1.position.y = -5;
    
        const cylinder1 = MeshBuilder.CreateCylinder("cylinder1", {height: 1, diameter: GsMesh.HANGER_DIAMETER/2} );
        cylinder1.position.y = -0.5 + GsMesh.HANGER_THICKNESS;
        cylinder1.position.z = -GsMesh.HANGER_DIAMETER/4;
    
        const cylinder2 = MeshBuilder.CreateCylinder("cylinder2", {height: 1, diameter: GsMesh.HANGER_DIAMETER/4} );
        cylinder2.position.y = -0.5 + GsMesh.HANGER_THICKNESS;
        cylinder2.position.z = GsMesh.HANGER_DIAMETER/8;
    
        const box2 = MeshBuilder.CreateBox("box2", {width: GsMesh.HANGER_DIAMETER/4, depth: GsMesh.HANGER_DIAMETER/4, height: 1} );
        box2.position.y = -0.5 + GsMesh.HANGER_THICKNESS;
        
        const cylinder3 = MeshBuilder.CreateCylinder("cylinder3", {height: GsMesh.HANGER_SPACING, diameter: GsMesh.HANGER_DIAMETER} );
        cylinder3.position.y = GsMesh.HANGER_SPACING/2 + GsMesh.HANGER_THICKNESS;
    
        const csgShape     = CSG.FromMesh( shape );
        const csgBox1      = CSG.FromMesh( box1 );
        const csgCylinder1 = CSG.FromMesh( cylinder1 );
        const csgCylinder2 = CSG.FromMesh( cylinder2 );
        const csgBox2      = CSG.FromMesh( box2 );
        const csgCylinder3 = CSG.FromMesh( cylinder3 );
        const csgResult    = csgShape.subtract( csgBox1 ).subtract( csgCylinder1 ).subtract( csgCylinder2 ).subtract( csgBox2 ).subtract( csgCylinder3 );
        
        const mesh = csgResult.buildMeshGeometry( "mesh" );
        
        this._subPositions = mesh.getVerticesData(VertexBuffer.PositionKind)!.slice();
        this._subNormals   = mesh.getVerticesData(VertexBuffer.NormalKind)!.slice();
        this._subIndices   = mesh.getIndices()!.slice();
        
        box1.dispose();
        shape.dispose();
        mesh.dispose();
        cylinder1.dispose();
        cylinder2.dispose();
        box2.dispose();
        cylinder3.dispose();
    }
    
    private extrude( quadIndex: number, distance: number, scale: number, rotationU: number, rotationV: number ): void {
        const quad   = this._quads[quadIndex];
        const normal = this.calcNormal( quad );
    
        const i0 = quad.x;
        const i1 = quad.y;
        const i2 = quad.z;
        const i3 = quad.w;
        
        const p0 = this._positions[i0];
        const p1 = this._positions[i1];
        const p2 = this._positions[i2];
        const p3 = this._positions[i3];
    
        // OFFSET
        let np0 = p0.add( normal.scale(distance) );
        let np1 = p1.add( normal.scale(distance) );
        let np2 = p2.add( normal.scale(distance) );
        let np3 = p3.add( normal.scale(distance) );
        
        const nc  = np0.add( np1 ).add( np2 ).add( np3 ).scale( 0.25 );
        let   nc0 = np0.subtract( nc );
        let   nc1 = np1.subtract( nc );
        let   nc2 = np2.subtract( nc );
        let   nc3 = np3.subtract( nc );
        
        // SCALE
        nc0 = nc0.scale( scale );
        nc1 = nc1.scale( scale );
        nc2 = nc2.scale( scale );
        nc3 = nc3.scale( scale );
    
        // ROTATE
        const axisU = np1.subtract( np0 ).normalizeToNew();
        const axisV = np3.subtract( np0 ).normalizeToNew();
        const rU    = Quaternion.RotationAxis( axisU, rotationU );
        const rV    = Quaternion.RotationAxis( axisV, rotationV );
        const r     = rU.multiply( rV );
    
        nc0.rotateByQuaternionToRef( r, nc0 );
        nc1.rotateByQuaternionToRef( r, nc1 );
        nc2.rotateByQuaternionToRef( r, nc2 );
        nc3.rotateByQuaternionToRef( r, nc3 );
    
        np0 = nc0.add( nc );
        np1 = nc1.add( nc );
        np2 = nc2.add( nc );
        np3 = nc3.add( nc );
    
        const i4 = this._positions.push( np0 ) - 1;
        const i5 = this._positions.push( np1 ) - 1;
        const i6 = this._positions.push( np2 ) - 1;
        const i7 = this._positions.push( np3 ) - 1;
        
        this._quads.splice( quadIndex, 1 );
    
        this._quads.push( new Vector4(i0, i4, i7, i3) );
        this._quads.push( new Vector4(i2, i6, i5, i1) );
        this._quads.push( new Vector4(i3, i7, i6, i2) );
        this._quads.push( new Vector4(i1, i5, i4, i0) );
        this._quads.push( new Vector4(i4, i5, i6, i7) );
    }
    
    private calcNormal( quad: Vector4 ): Vector3 {
        const p0 = this._positions[quad.x];
        const p1 = this._positions[quad.y];
        const p2 = this._positions[quad.z];
        const p3 = this._positions[quad.w];
    
        const t0e0 = p1.subtract( p0 );
        const t0e1 = p2.subtract( p0 );
    
        const t1e0 = p2.subtract( p0 );
        const t1e1 = p3.subtract( p0 );
    
        const n0 = t0e1.cross( t0e0 );
        const n1 = t1e1.cross( t1e0 );
        
        return n0.add( n1 ).normalizeToNew();
    }
    
    private randomExtrude(): void {
        const index     = this.randomInt( 0,  this._extrudable.length - 1 );
        const distance  = this.randomFloat( GsMesh.EXTRUSION_MIN, GsMesh.EXTRUSION_MAX );
        const scale     = this.randomFloat( GsMesh.SCALE_MIN, GsMesh.SCALE_MAX );
        const rotationU = this.randomFloat( GsMesh.ROTATION_MIN, GsMesh.ROTATION_MAX );
        const rotationV = this.randomFloat( GsMesh.ROTATION_MIN, GsMesh.ROTATION_MAX );
        const quadIndex = this._extrudable[index];
    
        this._extrudable.splice( index, 1 );
        for( let i = 0; i < this._extrudable.length; ++i ) {
            if( this._extrudable[i] > quadIndex ) {
                this._extrudable[i]--;
            }
        }
        
        this.extrude( quadIndex, distance, scale, rotationU, rotationV );
    
        this._extrudable.push( this._quads.length - 1 );
    }
    
    private randomInt( min: number, max: number ): number {
        return Math.round( this._randomGenerator.random() * (max - min) + min );
    }
    
    private randomFloat( min: number, max: number ): number {
        return this._randomGenerator.random() * (max - min) + min;
    }
}